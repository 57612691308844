import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import {
    Absence,
    AbsenceStatus,
    RosterNote,
    TimeEntry,
    TimeEntryPublishedInfo,
    TimeEntryType,
} from "@pentacode/core/src/model";
import { shared, animations } from "../../styles";
import { StateMixin } from "../../mixins/state";
import { Routing } from "../../mixins/routing";
import { app } from "../../init";
import { alert, confirm } from "../alert-dialog";
import {
    toDateString,
    dateAdd,
    formatWeekDayShort,
    formatDateShort,
    getRange,
    parseDateString,
    getDayOfWeek,
    toTimeString,
    displayDistinctWorkarea,
} from "@pentacode/core/src/util";
import "../scroller";
import "../spinner";
import { GetAbsencesParams, GetRosterNotesParams } from "@pentacode/core/src/api";
import "./../drawer";
import { minute } from "@pentacode/core/src/hours";
import "./../roster-note";
import { getHolidayForDate, Holiday } from "@pentacode/core/src/holidays";
import type { Popover } from "../popover";
import "../popover";
import "../date-picker";
import { matchesFilters } from "@pentacode/core/src/filters";
import { DateString } from "@pentacode/openapi/src/units";

interface Day {
    date: DateString;
    entries: TimeEntry[];
    absence?: Absence;
    notes: RosterNote[];
    isSunday: boolean;
    holiday?: Holiday | null;
}

interface RosterChange {
    before: TimeEntryPublishedInfo | null;
    after: TimeEntryPublishedInfo;
    seen: Date;
}

@customElement("ptc-staff-roster")
export class Roster extends Routing(StateMixin(LitElement)) {
    routePattern = /^(home|roster)/;

    @property({ reflect: true, type: Boolean })
    expanded = false;

    @property({ attribute: false })
    date: DateString;

    get changesCount() {
        return this._entries?.filter((e) => e.published && !!this._getChange(e)).length;
    }

    get availableCount() {
        return this._entries?.filter((e) => this._isAvailable(e)).length;
    }

    @state()
    private _entries: TimeEntry[];

    @state()
    private _absences: Absence[];

    @state()
    private _days: Day[] = [];

    @state()
    private _loading = false;

    @state()
    private _changes = new Map<string, RosterChange>();

    private _expanded = new Set<string>();

    private get _range() {
        return getRange(this.date, "week");
    }

    private _intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => this._intersect(entries),
        { threshold: 0.4 }
    );

    async connectedCallback() {
        super.connectedCallback();
        await app.loaded;
        if (app.account) {
            this._load();
        }
    }

    async updated(changes: Map<string, any>) {
        if (changes.has("date")) {
            this._load();
        }
        if (changes.has("_days")) {
            this._intersectionObserver.disconnect();
            this.renderRoot!.querySelectorAll(".vertical-roster .entry").forEach((e) =>
                this._intersectionObserver.observe(e)
            );

            const horizontalRoster = this.renderRoot.querySelector(".horizontal-roster");
            const today = horizontalRoster?.querySelector(
                `[data-date="${toDateString(new Date())}"]`
            ) as HTMLDivElement;
            if (today) {
                horizontalRoster!.scrollLeft = today.offsetLeft - 10;
            }
        }
    }

    refresh() {
        return this._load();
    }

    private _isAvailable(entry: TimeEntry, otherEntries: TimeEntry[] = this._entries) {
        return (
            !!app.profile &&
            this._entries &&
            this._absences &&
            app.isAvailable(entry, app.profile, otherEntries, this._absences)
        );
    }

    private _updateChanges(entries: TimeEntry[]) {
        for (const entry of entries) {
            if (!entry.published) {
                return;
            }

            const change = this._changes.get(entry.id);
            if (!change || !entry.isSeen) {
                this._changes.set(entry.id, {
                    before: entry.seen,
                    after: entry.published,
                    seen: new Date(),
                });
            }
        }
    }

    private _getChange(entry: TimeEntry, date: string = entry.published!.date): "added" | "changed" | "removed" | null {
        const profile = app.profile;
        const change = this._changes.get(entry.id);

        if (!profile || !change || Date.now() - change.seen.getTime() > 10 * minute) {
            return null;
        }

        if (
            change.after.employeeId === profile.id &&
            change.after.date === date &&
            (!change.before ||
                change.before.date !== change.after.date ||
                change.before.employeeId !== change.after.employeeId)
        ) {
            return "added";
        }

        if (
            change.before &&
            change.before.employeeId === profile.id &&
            change.before.date === date &&
            !change.before.deleted &&
            (change.after.deleted || change.after.date !== date || change.after.employeeId !== profile.id)
        ) {
            return "removed";
        }

        if (
            change.before &&
            (change.before.startPlanned?.getTime() !== change.after.startPlanned?.getTime() ||
                change.before.endPlanned?.getTime() !== change.after.endPlanned?.getTime() ||
                change.before.positionId !== change.after.positionId ||
                change.before.comment !== change.after.comment)
        ) {
            return "changed";
        }

        return null;
    }

    private async _load() {
        await app.loaded;
        const days: Day[] = [];

        this._loading = true;

        const today = toDateString(new Date());
        const { from, to } = this._range;

        try {
            let [entries, absences, notes] = await Promise.all([
                app.getTimeEntries({
                    from,
                    to,
                    type: [
                        TimeEntryType.Work,
                        TimeEntryType.Free,
                        TimeEntryType.CompDay,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                    ],
                    includeDeleted: true,
                    includeUnassigned: true,
                    includeOffered: true,
                }),
                app.api.getAbsences(
                    new GetAbsencesParams({
                        from,
                        to,
                        employee: app.profile!.id,
                        status: [AbsenceStatus.Approved, AbsenceStatus.Inferred],
                    })
                ),
                app.api.getRosterNotes(
                    new GetRosterNotesParams({
                        from: today,
                        to,
                    })
                ),
            ]);

            const company = app.company;
            const profile = app.profile;

            if (!company || !profile) {
                return;
            }

            // deduplicate entries
            this._entries = entries = [
                ...new Map<string, TimeEntry>(
                    entries.map((e) => [
                        `${e.published?.positionId} ${e.published?.date} ${e.published?.startPlanned} - ${e.published?.endPlanned} ${e.published?.employeeId} ${e.published?.deleted}`,
                        e,
                    ])
                ).values(),
            ]
                .filter(
                    (e) =>
                        e.published &&
                        (e.type !== TimeEntryType.Work || e.published.startPlanned) &&
                        (!e.published.deleted || !e.isSeen) &&
                        (e.employeeId === profile.id || this._isAvailable(e, entries))
                )
                .sort((a, b) => ((a.published?.startPlanned || "") < (b.published?.startPlanned || "") ? -1 : 1));

            let date = from;

            while (date < to) {
                days.push({
                    date,
                    entries: entries.filter((e) => e.published?.date === date || e.seen?.date === date),
                    absence: absences.find((a) => a.start <= date && a.end > date),
                    notes: notes.filter(
                        (n) =>
                            n.start <= date && n.end > date && matchesFilters(n.filters, { company, employee: profile })
                    ),
                    isSunday: getDayOfWeek(date) === 0,
                    holiday: getHolidayForDate(date, {
                        country: app.company!.country,
                        holidays: app.venues[0]?.enabledHolidays,
                    }),
                });
                date = dateAdd(date, { days: 1 });
            }

            this._updateChanges(entries);
            this._absences = absences;
            this._days = days;
            this.dispatchEvent(new CustomEvent("roster-loaded"));
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _intersect(intEntries: IntersectionObserverEntry[]) {
        if (this._loading) {
            return;
        }

        const updated: TimeEntry[] = [];
        for (const intEntry of intEntries) {
            if (intEntry.isIntersecting) {
                const entry = this._entries.find((e) => e.id === (intEntry.target as HTMLElement).dataset.entry);
                if (entry && entry.employeeId === app.profile!.id && !entry.isSeen) {
                    entry.setSeen();
                    updated.push(entry);
                }
            }
        }

        if (updated.length) {
            app.createOrUpdateTimeEntries(updated, { setUpdated: false });
        }
    }

    private async _takeShift(entry: TimeEntry) {
        if (
            !(await confirm("Wollen Sie diese Schicht übernehmen?", "Übernehmen", "Abbrechen", {
                title: "Schicht Übernehmen",
                icon: "hand-back-fist",
            }))
        ) {
            return;
        }

        this._loading = true;
        try {
            entry.employeeId = app.profile!.id;
            entry.taken = new Date();
            entry.setSeen();
            entry.published!.employeeId = entry.employeeId;
            await app.createOrUpdateTimeEntries([entry], { wait: true, setUpdated: false });
        } catch (e) {
            alert(e.message, { type: "warning" });
            this._load();
        }
        this._loading = false;
    }

    private async _offerUpShift(entry: TimeEntry) {
        if (
            !(await confirm("Wollen Sie diese Schicht zum Tausch freigeben?", "Freigeben", "Abbrechen", {
                title: "Zum Tausch Freigeben",
                icon: "hand-holding-magic",
            }))
        ) {
            return;
        }

        this._loading = true;
        try {
            entry.offered = new Date();
            await app.createOrUpdateTimeEntries([entry], { wait: true, setUpdated: false });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _undoOfferUpShift(entry: TimeEntry) {
        if (
            !(await confirm("Wollen Sie die Freigabe zum Tausch zurücknehmen?", "Zurücknehmen", "Abbrechen", {
                title: "Freigabe Zurücknehmen",
                icon: "undo",
            }))
        ) {
            return;
        }

        this._loading = true;
        try {
            entry.offered = null;
            await app.createOrUpdateTimeEntries([entry], { wait: true, setUpdated: false });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private _setExpanded(id: string, expanded: boolean) {
        expanded ? this._expanded.add(id) : this._expanded.delete(id);
        this.requestUpdate();
    }

    static styles = [
        shared,
        animations,
        css`
            :host {
                display: block;
                position: relative;
                will-change: transform;
            }

            .sticky {
                z-index: 3;
                left: 0;
            }

            .horizontal-roster,
            .vertical-roster {
                will-change: opacity;
                transition: opacity 0.3s;
            }

            :host([expanded]) .horizontal-roster,
            :host(:not([expanded])) .vertical-roster {
                opacity: 0;
                pointer-events: none;
            }

            .entry {
                padding-bottom: 0.2em;
                padding-left: 0.8em;
            }

            .entry.deleted {
                background: linear-gradient(
                    to top left,
                    transparent 0%,
                    transparent 49%,
                    var(--color-highlight) 49.5%,
                    var(--color-highlight) 50.5%,
                    transparent 51%,
                    transparent 100%
                );
            }

            .entry.available {
                border: dashed 1px;
            }

            .entry.offered {
                border: dotted 1px;
            }

            .entry .entry-status {
                padding: 0.2em 0.4em;
                color: var(--color-highlight);
                border: solid 1px;
                border-radius: 0.5em;
            }

            .entry.absence {
                padding: 1em 3em;
                text-align: center;
            }

            .collapsed-icon {
                transition: transform 0.2s;
            }

            .collapsed-icon.expanded {
                transform: rotate(90deg);
            }
        `,
    ];

    private _renderVertical() {
        const { from, to } = this._range;
        const today = toDateString(new Date());
        return html`
            <div class="fullbleed vertical layout vertical-roster">
                <ptc-scroller class="stretch" .locked=${!this.expanded}>
                    <div class="vertical layout fill-vertically relative">
                        ${this._days.map(
                            ({ date, entries, absence, notes, isSunday, holiday }) => html`
                                <div class="stretch padded border-bottom" style="min-height: 5em" data-date=${date}>
                                    <div class="padded background center-aligning horizontal layout">
                                        <div class="stretch ${isSunday ? "orange colored-text" : ""}">
                                            <strong>${formatWeekDayShort(date)}</strong>, ${formatDateShort(date)}
                                        </div>
                                        ${holiday ? html` <div class="violet colored-text">${holiday.name}</div> ` : ""}
                                        ${notes.length
                                            ? html`
                                                  <div
                                                      class="smaller bold pill click left-margined"
                                                      style="--color-highlight: ${notes[0].color || "unset"}"
                                                  >
                                                      <i class="sticky-note"></i> ${notes.length}
                                                  </div>
                                                  <ptc-popover
                                                      alignment="left-bottom"
                                                      positioning="absolute"
                                                      style="min-width: 16em; max-width: calc(100% - 8em);"
                                                  >
                                                      <ptc-scroller style="max-height: 20em;">
                                                          <div class="spacing vertical layout">
                                                              ${notes.map(
                                                                  (note) =>
                                                                      html`<ptc-roster-note
                                                                          .rosterNote=${note}
                                                                          .employee=${app.profile!}
                                                                      ></ptc-roster-note>`
                                                              )}
                                                          </div>
                                                      </ptc-scroller>
                                                  </ptc-popover>
                                              `
                                            : ""}
                                    </div>

                                    <div class="grid" style="--grid-gap: 0.5em; align-items: start;">
                                        ${entries.length
                                            ? entries.map((e) => this._renderEntry(e, date))
                                            : absence
                                              ? this._renderEntry(
                                                    new TimeEntry({
                                                        type: absence.type,
                                                        employeeId: absence.employeeId,
                                                        date,
                                                    }).setPublished(),
                                                    date
                                                )
                                              : html` <div class="padded semibold faded">Keine Einträge</div> `}
                                    </div>
                                </div>
                            `
                        )}
                    </div>
                </ptc-scroller>

                <div class="padded horizontal center-aligning spacing layout">
                    <div class="horizontal layout" style="min-width: 8em;">
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this.go(null, { date: dateAdd(this.date, { days: -7 }) })}
                        >
                            <i class="arrow-left"></i>
                        </button>
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this.go(null, { date: today })}
                            ?disabled=${this.date === today}
                        >
                            <i class="calendar-day"></i>
                        </button>
                    </div>
                    <div class="centering horizontal layout stretch">
                        <button class="transparent">
                            <strong>${formatDateShort(from)}</strong> -
                            <strong>${formatDateShort(dateAdd(to, { days: -1 }))}</strong> ${parseDateString(
                                this.date
                            )?.getFullYear()}
                        </button>
                        <ptc-popover positioning="absolute" alignment="top">
                            <ptc-date-picker
                                mode="week"
                                .value=${this.date}
                                @change=${(e: CustomEvent<{ value: string }>) => {
                                    this.go(null, { date: e.detail.value });
                                    ((e.target as HTMLElement).parentElement as Popover).hide();
                                }}
                            ></ptc-date-picker>
                        </ptc-popover>
                    </div>
                    <div class="horizontal end-justifying layout" style="min-width: 8em;">
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this.go(null, { date: dateAdd(this.date, { days: 7 }) })}
                        >
                            <i class="arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        `;
    }

    private _renderHorizontal() {
        return html`
            <div class="horizontal scroller horizontal-roster hide-scrollbar" style="margin-top: -1em;">
                <div class="margined horizontally-padded spacing start-aligning horizontal layout">
                    ${this._days.map(
                        ({ date, entries, absence }) => html`
                            <div class="start-aligning vertical layout" data-date=${date}>
                                <div class="padded background blue colored-text sticky">
                                    <strong>${formatWeekDayShort(date)}</strong>, ${formatDateShort(date)}
                                </div>

                                <div class="smaller spacing horizontal layout">
                                    ${entries.length
                                        ? entries.map((e) => this._renderEntry(e, date))
                                        : absence
                                          ? this._renderEntry(
                                                new TimeEntry({
                                                    type: absence.type,
                                                    employeeId: absence.employeeId,
                                                    date,
                                                }).setPublished(),
                                                date
                                            )
                                          : html` <div class="double-padded semibold faded">Keine Einträge</div> `}
                                </div>
                            </div>
                        `
                    )}
                </div>
            </div>
        `;
    }

    private _renderEntry(entry: TimeEntry, date: string) {
        const change = entry.published && this._getChange(entry, date);
        if (!entry.published || (entry.published.deleted && !change) || !app.company || !app.profile) {
            return;
        }
        const { positionId, startPlanned, endPlanned, comment } = entry.published;
        const position = positionId ? app.getPosition(positionId)?.position : null;
        const available = this._isAvailable(entry);
        const offered = entry.offered && entry.offered > entry.updated && (!entry.taken || entry.offered > entry.taken);
        const collapsed = !this.expanded || !this._expanded.has(entry.id);

        return !position
            ? html`
                  <div
                      class="padded box entry absence"
                      style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                      data-entry=${entry.id}
                  >
                      <div class="large">${app.localized.timeEntryTypeLabel(entry.type)}</div>
                  </div>
              `
            : available
              ? html`
                    <div
                        class="padded box entry strong shining available"
                        style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                        data-entry=${entry.id}
                        @mouseenter=${() => this._setExpanded(entry.id, true)}
                        @mouseleave=${() => this._setExpanded(entry.id, false)}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch ellipsis">
                                ${displayDistinctWorkarea(app.company, app.profile, position)}
                            </div>
                            <div class="smaller purple entry-status">verfügbar</div>
                            <i class="smaller chevron-right collapsed-icon ${collapsed ? "" : "expanded"}"></i>
                        </div>

                        <div class="huger semibold">
                            ${toTimeString(startPlanned) || "offen"} - ${toTimeString(endPlanned) || "offen"}
                        </div>

                        ${comment
                            ? html`
                                  <div>
                                      <pre><i class="comment"></i> ${comment}</pre>
                                  </div>
                              `
                            : ""}

                        <ptc-drawer .collapsed=${collapsed}>
                            <div class="tiny spacer"></div>
                            <div class="horizontal end-justifying layout">
                                <button class="smaller subtle" @click=${() => this._takeShift(entry)}>
                                    <i class="hand-back-fist"></i> Übernehmen
                                </button>
                            </div>
                            <div class="tiny spacer"></div>
                        </ptc-drawer>
                    </div>
                `
              : offered
                ? html`
                      <div
                          class="padded box entry strong offered"
                          style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                          data-entry=${entry.id}
                          @mouseenter=${() => this._setExpanded(entry.id, true)}
                          @mouseleave=${() => this._setExpanded(entry.id, false)}
                      >
                          <div class="horizontal spacing center-aligning layout">
                              <div class="stretch ellipsis">
                                  ${displayDistinctWorkarea(app.company, app.profile, position)}
                              </div>
                              <div class="smaller purple entry-status">zum Tausch freigegeben</div>
                              <i class="smaller chevron-right collapsed-icon ${collapsed ? "" : "expanded"}"></i>
                          </div>

                          <div class="huger semibold">
                              ${toTimeString(startPlanned) || "offen"} - ${toTimeString(endPlanned) || "offen"}
                          </div>

                          ${comment
                              ? html`
                                    <div>
                                        <pre><i class="comment"></i> ${comment}</pre>
                                    </div>
                                `
                              : ""}

                          <ptc-drawer .collapsed=${collapsed} ?hidden=${!app.hasPermission("staff.roster.offer")}>
                              <div class="tiny spacer"></div>
                              <div class="horizontal end-justifying layout">
                                  <button class="smaller subtle" @click=${() => this._undoOfferUpShift(entry)}>
                                      <i class="undo"></i> Freigabe Zurücknehmen
                                  </button>
                              </div>
                              <div class="tiny spacer"></div>
                          </ptc-drawer>
                      </div>
                  `
                : change === "removed"
                  ? html`
                        <div
                            class="padded box entry strong shining deleted"
                            style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                            data-entry=${entry.id}
                        >
                            <div class="horizontal spacing center-aligning layout">
                                <div class="stretch ellipsis">
                                    ${displayDistinctWorkarea(app.company, app.profile, position)}
                                </div>
                                <div class="smaller orange entry-status">gestrichen</div>
                            </div>
                            <div class="huger semibold">
                                ${toTimeString(entry.seen?.startPlanned) || "offen"} -
                                ${toTimeString(entry.seen?.endPlanned) || "offen"}
                            </div>

                            ${entry.seen?.comment
                                ? html`
                                      <div>
                                          <pre><i class="comment"></i> ${entry.seen.comment}</pre>
                                      </div>
                                  `
                                : ""}
                        </div>
                    `
                  : change === "changed"
                    ? html`
                          <div class="center-aligning horizontal layout" style="grid-column: span 2;">
                              <div
                                  class="padded box entry strong shining deleted stretch"
                                  style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                                  data-entry=${entry.id}
                              >
                                  <div class="horizontal spacing center-aligning layout">
                                      <div class="stretch ellipsis">
                                          ${displayDistinctWorkarea(app.company, app.profile, position)}
                                      </div>
                                      <div class="smaller orange entry-status">geändert</div>
                                  </div>
                                  <div class="huger semibold">
                                      ${toTimeString(entry.seen?.startPlanned) || "offen"} -
                                      ${toTimeString(entry.seen?.endPlanned) || "offen"}
                                  </div>

                                  ${entry.seen?.comment
                                      ? html`
                                            <div>
                                                <pre><i class="comment"></i> ${entry.seen.comment}</pre>
                                            </div>
                                        `
                                      : ""}
                              </div>

                              <i class="arrow-right changed-arrow"></i>

                              <div
                                  class="padded box entry strong shining stretch"
                                  style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                                  data-entry=${entry.id}
                                  @mouseenter=${() => this._setExpanded(entry.id, true)}
                                  @mouseleave=${() => this._setExpanded(entry.id, false)}
                              >
                                  <div class="horizontal spacing center-aligning layout">
                                      <div class="stretch ellipsis">
                                          ${displayDistinctWorkarea(app.company, app.profile, position)}
                                      </div>
                                      <div class="smaller orange entry-status">geändert</div>
                                      <i
                                          class="smaller chevron-right collapsed-icon ${collapsed ? "" : "expanded"}"
                                      ></i>
                                  </div>

                                  <div class="huger semibold">
                                      ${toTimeString(startPlanned) || "offen"} - ${toTimeString(endPlanned) || "offen"}
                                  </div>

                                  ${comment
                                      ? html`
                                            <div>
                                                <pre><i class="comment"></i> ${comment}</pre>
                                            </div>
                                        `
                                      : ""}

                                  <ptc-drawer
                                      .collapsed=${collapsed}
                                      ?hidden=${!app.hasPermission("staff.roster.offer")}
                                  >
                                      <div class="tiny spacer"></div>
                                      <div class="horizontal end-justifying layout">
                                          <button class="smaller subtle" @click=${() => this._offerUpShift(entry)}>
                                              <i class="hand-holding-magic"></i> Zum Tausch Freigeben
                                          </button>
                                      </div>
                                      <div class="tiny spacer"></div>
                                  </ptc-drawer>
                              </div>
                          </div>
                      `
                    : change === "added"
                      ? html`
                            <div
                                class="padded box entry strong shining"
                                style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                                data-entry=${entry.id}
                                @mouseenter=${() => this._setExpanded(entry.id, true)}
                                @mouseleave=${() => this._setExpanded(entry.id, false)}
                            >
                                <div class="horizontal spacing center-aligning layout">
                                    <div class="stretch ellipsis">
                                        ${displayDistinctWorkarea(app.company, app.profile, position)}
                                    </div>
                                    <div class="smaller orange entry-status">neu</div>
                                    <i class="smaller chevron-right collapsed-icon ${collapsed ? "" : "expanded"}"></i>
                                </div>

                                <div class="huger semibold">
                                    ${toTimeString(startPlanned) || "offen"} - ${toTimeString(endPlanned) || "offen"}
                                </div>

                                ${comment
                                    ? html`
                                          <div>
                                              <pre><i class="comment"></i> ${comment}</pre>
                                          </div>
                                      `
                                    : ""}

                                <ptc-drawer .collapsed=${collapsed} ?hidden=${!app.hasPermission("staff.roster.offer")}>
                                    <div class="tiny spacer"></div>
                                    <div class="horizontal end-justifying layout">
                                        <button class="smaller subtle" @click=${() => this._offerUpShift(entry)}>
                                            <i class="hand-holding-magic"></i> Zum Tausch Freigeben
                                        </button>
                                    </div>
                                    <div class="tiny spacer"></div>
                                </ptc-drawer>
                            </div>
                        `
                      : html`
                            <div
                                class="padded box entry"
                                style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                                data-entry=${entry.id}
                                @mouseenter=${() => this._setExpanded(entry.id, true)}
                                @mouseleave=${() => this._setExpanded(entry.id, false)}
                            >
                                <div class="horizontal spacing center-aligning layout">
                                    <div class="stretch ellipsis">
                                        ${displayDistinctWorkarea(app.company, app.profile, position)}
                                    </div>
                                    <i class="smaller chevron-right collapsed-icon ${collapsed ? "" : "expanded"}"></i>
                                </div>

                                <div class="huger semibold">
                                    ${toTimeString(startPlanned) || "offen"} - ${toTimeString(endPlanned) || "offen"}
                                </div>

                                ${comment
                                    ? html`
                                          <div>
                                              <pre><i class="comment"></i> ${comment}</pre>
                                          </div>
                                      `
                                    : ""}

                                <ptc-drawer .collapsed=${collapsed} ?hidden=${!app.hasPermission("staff.roster.offer")}>
                                    <div class="tiny spacer"></div>
                                    <div class="horizontal end-justifying layout">
                                        <button class="smaller subtle" @click=${() => this._offerUpShift(entry)}>
                                            <i class="hand-holding-magic"></i> Zum Tausch Freigeben
                                        </button>
                                    </div>
                                    <div class="tiny spacer"></div>
                                </ptc-drawer>
                            </div>
                        `;
    }

    render() {
        if (!app.account || !app.profile) {
            return;
        }

        return html`
            ${this._renderVertical()} ${this._renderHorizontal()}

            <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
