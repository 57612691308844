import { config, shared } from "@pentacode/app/src/styles";
import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@pentacode/app/src/elements/scroller";
import {
    aggregateTimeReportItems,
    formatDate,
    formatRange,
    formatWeekDayShort,
    getRange,
    toDurationString,
} from "@pentacode/core/src/util";
import { DateString } from "@pentacode/openapi/src/units";
import "@pentacode/app/src/elements/date-range-picker";
import { Routing } from "../../mixins/routing";
import { StateMixin } from "../../mixins/state";
import { GetTimeEntriesParams, GetTimeReportsParams, TimeEntry, TimeEntryType, TimeReport } from "@pentacode/core";
import { app } from "../../init";
import { alert } from "@pentacode/app/src/elements/alert-dialog";

@customElement("ptc-staff-time")
export class StaffTime extends Routing(StateMixin(LitElement)) {
    readonly routePattern = /home|time/;

    @property({ reflect: true, type: Boolean })
    expanded = false;

    @property({ attribute: false })
    date: DateString;

    @state()
    private _loading = false;

    @state()
    private _timeEntriesByDate: {
        date: DateString;
        timeEntries: TimeEntry[];
    }[] = [];

    @state()
    private _timeReport: TimeReport | null = null;

    private get _dateRange() {
        return getRange(this.date, "month");
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("date")) {
            void this._load();
        }
    }

    static styles = [
        config,
        shared,
        css`
            :host {
                display: block;
                position: relative;
                background: var(--color-bg);
                margin-top: -0.5em;
            }

            .header {
                margin-left: 2em;
                border-left: 1px solid var(--shade-2);
                position: relative;
            }

            .month-header {
                margin-left: -1.1em;
            }

            .month-icon {
                border: solid 1px;
                border-color: var(--shade-2);
                padding: 0.2em;
                background: var(--color-bg);
            }

            .shift-time {
                background: var(--color-bg);
                white-space: nowrap;
            }

            .day {
                margin-left: 2em;
                border-left: 1px solid var(--shade-2);
                padding: 0.5em;
                position: relative;
            }

            .day-icon {
                border: solid 1px;
                border-color: var(--shade-2);
                padding: 0.2em;
                background: var(--color-bg);
            }

            .day-header {
                margin-left: -1.55em;
            }

            .timeline-wrapper {
                padding: 0.5em 5em 0.5em 2em;
                height: 2em;
            }

            .shift-icon {
                border: solid 1px;
                padding: 0.2em;
                background: var(--color-bg);
            }

            .position-header {
                color: var(--color-highlight);
                position: relative;
                margin-left: -2.2em;
            }
        `,
    ];

    async refresh() {
        await this._load();
    }

    private async _load() {
        if (!app.profile || !this._dateRange) {
            return;
        }

        const { from, to } = this._dateRange;
        if (!from || !to) {
            return;
        }

        this._loading = true;

        try {
            const [timeEntries, timeReports] = await Promise.all([
                app.api.getTimeEntries(
                    new GetTimeEntriesParams({
                        from,
                        to,
                        employee: [app.profile.id],
                    })
                ),
                app.api.getTimeReports(
                    new GetTimeReportsParams({
                        from,
                        to,
                        filters: [{ type: "employeeId", value: app.profile.id }],
                        resolution: "month",
                        types: [
                            TimeEntryType.Work,
                            TimeEntryType.Vacation,
                            TimeEntryType.Sick,
                            TimeEntryType.SickInKUG,
                            TimeEntryType.ChildSick,
                            TimeEntryType.HourAdjustment,
                            TimeEntryType.CompDay,
                        ],
                    })
                ),
            ]);

            const timeEntriesByDate = new Map<DateString, TimeEntry[]>();

            for (const timeEntry of timeEntries) {
                if (
                    !timeEntry.result?.base.duration ||
                    ![
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.SickInKUG,
                        TimeEntryType.ChildSick,
                    ].includes(timeEntry.type)
                ) {
                    continue;
                }

                if (!timeEntriesByDate.has(timeEntry.date)) {
                    timeEntriesByDate.set(timeEntry.date, []);
                }

                timeEntriesByDate.get(timeEntry.date)!.push(timeEntry);
            }

            this._timeEntriesByDate = [...timeEntriesByDate.entries()].map(([date, timeEntries]) => ({
                date,
                timeEntries,
            }));
            this._timeReport = timeReports[0];
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _formatTime(date: Date) {
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    }

    private _renderDay(
        { date, timeEntries }: (typeof this._timeEntriesByDate)[number],
        minTime: number,
        maxTime: number
    ) {
        const timeRange = maxTime - minTime;
        return html`
            <div class="day">
                <div class="center-aligning horizontal layout day-header">
                    <i class="calendar-day day-icon"></i>
                    <div class="padded subtle">${formatWeekDayShort(date)}, <strong>${formatDate(date)}</strong></div>
                </div>
                <div class="smaller padded spacing vertical layout">
                    ${timeEntries.map((timeEntry) => {
                        if (!timeEntry.result) {
                            return;
                        }
                        const startPosition =
                            timeEntry.startFinal &&
                            ((new Date(timeEntry.startFinal).getTime() - new Date(timeEntry.date).getTime() - minTime) /
                                timeRange) *
                                100;
                        const endPosition =
                            timeEntry.endFinal &&
                            ((new Date(timeEntry.endFinal).getTime() - new Date(timeEntry.date).getTime() - minTime) /
                                timeRange) *
                                100;
                        return html`
                            <div class="time-entry">
                                <div class="spacing center-aligning horizontal layout">
                                    <div
                                        class="center-aligning horizontal layout position-header"
                                        style="--color-highlight: ${app.getTimeEntryColor(timeEntry)}"
                                    >
                                        <i
                                            class="${app.localized.timeEntryTypeIcon(
                                                timeEntry.type
                                            )} semibold shift-icon"
                                        ></i>
                                        <div class="padded">${app.getTimeEntryLabel(timeEntry)}</div>
                                    </div>
                                    <div class="stretch ellipsis">
                                        ${timeEntry.comment ? html`<i class="comment"></i> ${timeEntry.comment}` : ""}
                                    </div>
                                    ${timeEntry.result.meals.breakfast
                                        ? html`
                                              <div class="brown semibold colored-text">
                                                  <i class="pancakes"></i>
                                              </div>
                                          `
                                        : ""}
                                    ${timeEntry.result.meals.lunch
                                        ? html`
                                              <div class="brown semibold colored-text">
                                                  <i class="burger-fries"></i>
                                              </div>
                                          `
                                        : ""}
                                    ${timeEntry.result.meals.dinner
                                        ? html`
                                              <div class="brown semibold colored-text">
                                                  <i class="plate-utensils"></i>
                                              </div>
                                          `
                                        : ""}
                                    ${timeEntry.result.breaks.duration
                                        ? html`
                                              <div class="orange semibold colored-text">
                                                  <i class="coffee"></i> ${toDurationString(
                                                      timeEntry.result.breaks.duration
                                                  )}
                                              </div>
                                          `
                                        : ""}
                                    <div class="blue semibold colored-text">
                                        <i class="timer"></i> ${toDurationString(timeEntry.result.base.duration)}
                                    </div>
                                </div>
                                ${timeEntry.startFinal && timeEntry.endFinal && startPosition && endPosition
                                    ? html`
                                          <div class="timeline-wrapper">
                                              <div class="relative">
                                                  <div
                                                      style="position: absolute; height: 2em; left: ${startPosition}%; width: ${endPosition -
                                                      startPosition}%;"
                                                  >
                                                      <div
                                                          style="position: absolute; inset: 0; margin: auto; height: 1px; background: ${app.getTimeEntryColor(
                                                              timeEntry
                                                          )}"
                                                      ></div>
                                                      <div
                                                          class="pill absolute shift-time"
                                                          style="left: 0; transform: translateX(-100%);"
                                                      >
                                                          <i class="play"></i> ${this._formatTime(timeEntry.startFinal)}
                                                      </div>
                                                      <div
                                                          class="pill absolute shift-time"
                                                          style="right: 0; transform: translateX(100%);"
                                                      >
                                                          <i class="stop"></i> ${this._formatTime(timeEntry.endFinal)}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      `
                                    : ""}
                            </div>
                        `;
                    })}
                </div>
            </div>
        `;
    }

    render(): TemplateResult {
        const workTimeEntries = this._timeEntriesByDate
            .flatMap(({ timeEntries }) => timeEntries)
            .filter((timeEntry) => timeEntry.startFinal && timeEntry.endFinal);
        const minTime = 0;
        // The default time range is from 00:00 to 24:00 - if there are any shifts that reach into the next
        // day, we'll extend the time window up accordingly
        const maxTime = Math.max(
            ...workTimeEntries.map(
                (timeEntry) => new Date(timeEntry.endFinal!).getTime() - new Date(timeEntry.date).getTime()
            ),
            24 * 60 * 60 * 1000
        );

        const breakdown = aggregateTimeReportItems(this._timeReport?.items || []);

        return html`
            <div class="fullbleed vertical layout" style="background: var(--color-bg); z-index: 1;">
                <div class="header">
                    <div class="larger semibold center-aligning horizontal layout month-header">
                        <i class="calendar month-icon"></i>
                        <div class="horizontally-padded">${formatRange(this._dateRange)}</div>
                    </div>
                    <div class="double-spacing horizontal layout" style="padding: 0.5em 1em 1em 1em">
                        <div>
                            <div class="brown colored-text bottom-margined"><i class="digging"></i> Arbeit</div>
                            <div class="half-spacing center-aligning horizontal layout">
                                <i class="smaller hourglass-clock"></i>
                                <div>${toDurationString(breakdown.work.hours)}</div>
                                <i class="smaller calendar-day"></i>
                                <div>${breakdown.work.days}</div>
                            </div>
                        </div>
                        <div>
                            <div class="blue colored-text bottom-margined"><i class="umbrella-beach"></i> Urlaub</div>
                            <div class="half-spacing center-aligning horizontal layout">
                                <i class="smaller hourglass-clock"></i>
                                <div>${toDurationString(breakdown.vacation.hours)}</div>
                                <i class="smaller calendar-day"></i>
                                <div>${breakdown.vacation.days}</div>
                            </div>
                        </div>
                        <div>
                            <div class="orange colored-text bottom-margined">
                                <i class="face-thermometer"></i> Krank
                            </div>
                            <div class="half-spacing center-aligning horizontal layout">
                                <i class="smaller hourglass-clock"></i>
                                <div>${toDurationString(breakdown.sick.hours)}</div>
                                <i class="smaller calendar-day"></i>
                                <div>${breakdown.sick.days}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <ptc-scroller class="stretch">
                    ${this._timeEntriesByDate.map((item) => this._renderDay(item, minTime, maxTime))}
                    ${this._timeEntriesByDate.length === 0
                        ? html`
                              <div class="double-padded fullbleed centering layout subtle">
                                  Es liegen keine Arbeitszeiten in diesem Zeitraum vor.
                              </div>
                          `
                        : ""}
                </ptc-scroller>

                <div class="padded vertical layout">
                    <ptc-month-picker
                        .max=${getRange(new Date(), "month").to}
                        @change=${({ detail: { date } }: CustomEvent<{ date: string }>) => this.go(null, { date })}
                    ></ptc-month-picker>
                </div>

                <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
