import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { Document, FormStatus, TimeBalance, VacationBalance } from "@pentacode/core/src/model";
import {
    formatDateShort,
    toDurationString,
    formatNumber,
    toDateString,
    getRange,
    formatRange,
    formatRangeShort,
} from "@pentacode/core/src/util";
import { shared, animations } from "../../styles";
import { Routing, routeProperty } from "../../mixins/routing";
import { StateMixin } from "../../mixins/state";
import { app } from "../../init";
import "../avatar";
import { Balance } from "../balance";
import "../spinner";
import anime from "animejs";
import logo from "../../img/logo.svg";
import { HelpWidget } from "../help";
import { singleton } from "../../lib/singleton";
import { version } from "@pentacode/core/src/version";
import { Roster } from "./roster";
import { Tracking } from "./tracking";
import { Absences } from "./absences";
import { Availabilities } from "./availabilities";
import { Documents } from "./documents";
import { StaffTime } from "./time";
import { GetBalancesParams, GetDocumentsParams } from "@pentacode/core/src/api";
import { alert } from "../alert-dialog";
import { DocumentDialog } from "../document-dialog";
import { Hours } from "@pentacode/openapi";
import { getVacationEntitlement } from "@pentacode/core";

@customElement("ptc-staff-home")
export class Home extends Routing(StateMixin(LitElement)) {
    readonly routePattern = /^(?<page>home|tracking|roster|time|absences|availabilities|documents)/;

    get routeTitle() {
        switch (this._page) {
            case "home":
                return "Übersicht";
            case "tracking":
                return "Zeiterfassung";
            case "roster":
                return "Dienstplan";
            case "time":
                return "Arbeitszeiten";
            case "absences":
                return "Urlaub & Krankmeldungen";
            case "availabilities":
                return "Verfügbarkeiten";
            case "documents":
                return "Dokumente";
        }
    }

    @routeProperty({ arg: "page" })
    private _page: string;

    @state()
    private _loading = false;

    @state()
    private _pendingDocumentForms: Document[] = [];

    @state()
    private _currentTimeBalance?: TimeBalance;

    @state()
    private _currentVacationBalance?: VacationBalance;

    @query(".scroller")
    private _scroller: HTMLElement;

    @query(".expanded")
    private _expandedSection: HTMLElement;

    @query(".hero-background")
    private _heroBackground: HTMLElement;

    @query(".hero-inner")
    private _heroInner: HTMLElement;

    @query("ptc-staff-roster")
    private _roster: Roster;

    @query("ptc-staff-tracking")
    private _tracking: Tracking;

    @query("ptc-staff-absences")
    private _absences: Absences;

    @query("ptc-staff-availabilities")
    private _availabilities: Availabilities;

    @query("ptc-staff-documents")
    private _documents: Documents;

    @query("ptc-staff-time")
    private _time: StaffTime;

    @singleton("ptc-help")
    private _helpWidget: HelpWidget;

    @singleton("ptc-document-dialog")
    private _documentDialog: DocumentDialog;

    private _lastScrollPosition?: number;

    private get _greeting() {
        if (!app.profile) {
            return null;
        }

        const hour = new Date().getHours();
        const isBirthday = app.profile.isBirthDay(new Date());

        const greeting = isBirthday
            ? "Alles Gute zum Geburtstag, "
            : hour < 10
              ? "Guten Morgen,"
              : hour <= 17
                ? "Guten Tag,"
                : "Guten Abend,";
        const name = app.profile.callName || app.profile.firstName;
        const emoji = isBirthday ? "🥳" : hour < 10 ? "🌅" : hour <= 17 ? "🌞" : "🌛";

        return html` ${greeting} <strong>${name}</strong>! ${emoji} `;
    }

    private async _load() {
        if (!app.profile) {
            return;
        }
        this._loading = true;
        const start = Date.now();
        try {
            const [timeBalances, vacationBalances, documents] = await Promise.all([
                app.api.getTimeBalances(
                    new GetBalancesParams({
                        filters: [{ type: "employeeId", value: app.profile.id }],
                        ...getRange(new Date(), "month"),
                        resolution: "month",
                    })
                ),
                app.api.getVacationBalances(
                    new GetBalancesParams({
                        filters: [{ type: "employeeId", value: app.profile.id }],
                        ...getRange(new Date(), "year"),
                        resolution: "year",
                    })
                ),
                app.api.getDocuments(
                    new GetDocumentsParams({ employeeId: app.profile.id, formStatus: FormStatus.InProgress })
                ),
                app.fetchAccount(),
                app.fetchCompany(),
            ]);
            this._pendingDocumentForms = documents;
            this._currentTimeBalance = timeBalances[0];
            this._currentVacationBalance = vacationBalances[0];
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        setTimeout(
            () => {
                this._loading = false;
            },
            Math.max(0, 1000 - Date.now() + start)
        );
    }

    private async _refresh() {
        if (!app.account) {
            return;
        }
        await Promise.all([
            this._load(),
            this._roster?.refresh(),
            this._tracking?.refresh(),
            this._absences?.refresh(),
            this._availabilities?.refresh(),
            this._documents?.refresh(),
            this._time?.refresh(),
        ]);
        this._animate();
    }

    private _animate() {
        const animated = this.renderRoot!.querySelectorAll(".animate") as NodeListOf<HTMLElement>;
        let delay = 0;
        for (const el of animated) {
            el.style.animationDelay = delay + "ms";
            el.style.animationPlayState = "running";
            delay += 50;
        }
    }

    private _loaded = false;

    async handleRoute() {
        const today = toDateString(new Date());
        if (!this.date || (this._page === "home" && this.date !== today)) {
            this.go(null, { date: today }, true);
            return;
        }

        if (app.account && !this._loaded) {
            await this._load();
            await this.updateComplete;
            this._animate();
            this._loaded = true;
            this.requestUpdate("_page");
        }
    }

    async updated(changes: Map<string, string>) {
        if (changes.has("_page")) {
            if (!this._scroller) {
                return;
            }
            const expanded = this._expandedSection;
            const scroller = this._scroller;
            let scrollTop: number | undefined;

            if (expanded) {
                this._lastScrollPosition = scroller.scrollTop;
                scrollTop = expanded.offsetTop;
                scroller.style.pointerEvents = "none";
            } else {
                scrollTop = this._lastScrollPosition;
                scroller.style.pointerEvents = "";
                this._lastScrollPosition = undefined;
            }

            if (typeof scrollTop === "number") {
                anime({
                    targets: scroller,
                    scrollTop,
                    duration: 300,
                    easing: "cubicBezier(0.35, 0.35, 0.1, 1.1)",
                });
            }
        }
    }

    pageFocused() {
        void this._refresh();
    }

    private _closeSection(e: Event) {
        e.stopPropagation();
        this.go("home");
    }

    private _scroll() {
        const scrollTop = this._scroller.scrollTop;
        this._heroBackground.style.transform = `translate(0, ${-scrollTop / 5}px)`;
        this._heroInner.style.transform = `translate(0, ${-scrollTop / 10}px)`;
    }

    private async _updateAvatar({ detail: { image } }: CustomEvent) {
        await app.updateAccount({ profile: { avatar: image } });
    }

    private async _openDocument(document: Document) {
        const edited = await this._documentDialog.show(document);
        if (edited) {
            await this._load();
        }
    }

    static styles = [
        shared,
        animations,
        Balance.styles,
        css`
            .section-header {
                margin: 0.5em;
                font-size: var(--font-size-bigger);
                font-weight: 600;
                position: relative;
            }

            .section-header > i {
                margin-right: 0.25em;
            }

            .horizontal-scroller {
                overflow-x: auto;
            }

            .scroller {
                overflow-y: auto;
                scroll-snap-type: y mandatory;
            }

            .scroller::-webkit-scrollbar {
                display: none !important;
            }

            .section {
                will-change: transform, height, margin, box-shadow;
                transition:
                    all 0.3s cubic-bezier(0.35, 0.35, 0.1, 1.1),
                    transform 0.1s;
                overflow: hidden;
                height: 250px;
                pointer-events: auto;
                box-shadow: none !important;
                border: solid 1px var(--shade-1);
            }

            .section.expanded {
                /*scroll-snap-align: start;*/
                margin: 0;
                box-shadow: none;
                height: 100% !important;
                border-color: transparent;
            }

            .section:active:not(.expanded) {
                transform: scale(0.98);
            }

            .section:not(.expanded) .close-button,
            .section.expanded .open-button {
                display: none;
            }

            .stats-grid .card {
                padding: 0.6em 0.75em 0.5em 0.75em;
                box-shadow: none;
                border: solid 1px var(--shade-1);
            }

            .stats-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
                grid-gap: 1em;
            }

            .stats-value {
                margin: 0.1em 0 -0.1em 0;
            }

            .section.roster {
                height: 10.5em;
            }

            .section.tracking {
                height: 12em;
            }

            .logo {
                height: 1.5em;
            }

            .stop1 {
                stop-color: var(--blue);
            }

            .stop2 {
                stop-color: var(--aqua);
            }

            .hero {
                color: var(--color-bg);
                text-shadow: 0 0.1em 0 rgb(0 0 0 / 10%);
            }

            .hero-background {
                position: absolute;
                z-index: 0;
                width: 100%;
                height: 100%;
                will-change: transform;
            }

            .hero ptc-avatar {
                will-change: transform;
            }

            .hero-inner {
                position: relative;
                z-index: 1;
            }

            .greeting {
                max-width: 12em;
                margin: 1em auto;
            }

            @keyframes slideIn {
                from {
                    transform: translate(0, 1em);
                    opacity: 0;
                }
            }

            .animate {
                animation: slideIn 1s backwards;
                animation-play-state: paused;
            }
        `,
    ];

    private _renderStats() {
        const timeBalance = this._currentTimeBalance;
        const vacationEntitlement = this._currentVacationBalance
            ? getVacationEntitlement(this._currentVacationBalance)
            : null;
        return html`
            <div class="horizontal double-margined stats-grid">
                ${app.hasPermission("staff.time")
                    ? html`
                          <div class="card animate ${this._loading ? "strong shining" : ""}">
                              <div class="horizontal spacing center-aligning layout">
                                  <div class="blue colored-text semibold stretch">Stunden</div>
                                  <div class="subtle smaller semibold">
                                      ${timeBalance ? formatRangeShort(timeBalance) : "..."}
                                  </div>
                              </div>
                              <div class="horizontal end-aligning layout">
                                  <div class="huger stretch stats-value">
                                      ${timeBalance ? toDurationString(timeBalance.actual) : "..."}
                                  </div>
                                  <div class="semibold faded">
                                      / ${timeBalance ? toDurationString(timeBalance.nominal) : "..."}
                                  </div>
                              </div>
                          </div>
                      `
                    : ""}
                ${app.hasPermission("staff.ledgers")
                    ? html`
                          <div class="card animate ${this._loading ? "strong shining" : ""}">
                              <div class="horizontal spacing center-aligning layout">
                                  <div class="blue colored-text semibold stretch">Überst.</div>
                                  <div class="subtle smaller semibold">
                                      Stand ${timeBalance ? formatDateShort(timeBalance.from) : "..."}
                                  </div>
                              </div>
                              <div class="center-aligning horizontal layout">
                                  ${timeBalance
                                      ? html`
                                            <ptc-balance
                                                class="huger stats-value"
                                                .value=${timeBalance.reset ?? timeBalance.carry}
                                                .format=${(val: Hours) => toDurationString(val, true)}
                                            ></ptc-balance>
                                        `
                                      : html`<div class="huger stats-value">...</div>`}
                              </div>
                          </div>

                          <div class="card animate ${this._loading ? "strong shining" : ""}">
                              <div class="horizontal spacing center-aligning layout">
                                  <div class="blue colored-text semibold stretch">Resturlaub</div>
                                  <div class="subtle smaller semibold">
                                      ${this._currentVacationBalance
                                          ? formatRange(this._currentVacationBalance)
                                          : "..."}
                                  </div>
                              </div>
                              <div class="horizontal end-aligning layout">
                                  <div class="huger stretch stats-value">
                                      ${vacationEntitlement ? formatNumber(vacationEntitlement.remaining) : "..."}
                                  </div>
                                  <div class="semibold faded">
                                      / ${vacationEntitlement ? formatNumber(vacationEntitlement.available) : "..."}
                                  </div>
                              </div>
                          </div>
                      `
                    : ""}
            </div>
        `;
    }

    private _renderPendingForm() {
        const [document] = this._pendingDocumentForms;
        const createdBy = document?.createdById && app.getEmployee(document.createdById);

        return document
            ? html`
                  <div class="double-padded double-margined card shining">
                      <div class="start-aligning spacing horizontal layout">
                          <i class="huge pen-field"></i>
                          <div class="stretch">
                              <div class="small subtle colored-text">
                                  ${createdBy
                                      ? html`<strong>${createdBy.name}</strong> hat dich gebeten, folgendes Formular
                                            auszufüllen:`
                                      : html`Du wurdest gebeten, folgendes Formular auszufüllen: `}
                              </div>
                              <div class="large">${document.form?.name}</div>
                          </div>
                      </div>
                      <div class="top-margined top-padded vertical layout">
                          <button class="primary" @click=${() => this._openDocument(document)}>Jetzt Ausfüllen</button>
                      </div>
                  </div>
              `
            : "";
    }

    render() {
        const profile = app.profile;
        const rosterChanges = this._roster?.changesCount;
        const available = this._roster?.availableCount;

        if (!profile) {
            return;
        }

        return html`
            <div class="fullbleed scroller" @scroll=${this._scroll}>
                <div class="hero relative vertical layout">
                    <svg
                        viewBox="0 -150 100 260"
                        preserveAspectRatio="none"
                        overflow="visible"
                        class="hero-background animate"
                    >
                        <defs>
                            <linearGradient id="gradient" class="svg-gradient" gradientTransform="rotate(80)">
                                <stop offset="0%" class="stop1" />
                                <stop offset="100%" class="stop2" />
                            </linearGradient>
                        </defs>

                        <path d="M 0 -170 L 0 25 Q 25 10, 50 50 Q 75 95, 100 75 L 100 -170 Z" fill="url(#gradient)" />
                    </svg>

                    <div class="hero-inner">
                        <div class="double-margined padded text-centering subtle semibold animate">
                            ${app.company!.name}
                        </div>

                        <div class="padded bigger text-centering greeting animate">${this._greeting}</div>
                        <div class="double-margined centering vertical layout animate">
                            <ptc-avatar
                                .employee=${profile}
                                class="enormous"
                                editable
                                @edit=${this._updateAvatar}
                            ></ptc-avatar>
                        </div>
                    </div>

                    <div class="absolute top right margined subtle" style="z-index: 1">
                        <button class="transparent large bouncy" @click=${this._refresh} ?invisible=${this._loading}>
                            <i class="redo-alt"></i>
                        </button>
                        <ptc-spinner
                            class="white tiny centered bouncy"
                            ?active=${this._loading}
                            ?invisible=${!this._loading}
                        ></ptc-spinner>
                    </div>
                </div>

                ${this._renderPendingForm()} ${this._renderStats()}
                ${app.hasPermission("staff.tracking")
                    ? html`
                          <div
                              class="tracking section double-margined card vertical layout animate ${this._page ===
                              "tracking"
                                  ? "expanded"
                                  : ""}"
                              @click=${() => this._page !== "tracking" && this.go("tracking")}
                          >
                              <div class="horizontal center-aligning layout section-header">
                                  <button class="transparent small close-button" @click=${this._closeSection}>
                                      <i class="chevron-left"></i>
                                  </button>
                                  <div class="larger small-caps stretch left-margined">Zeiterfassung</div>
                                  <button class="transparent small open-button">
                                      <i class="chevron-right"></i>
                                  </button>
                              </div>
                              <ptc-staff-tracking
                                  class="stretch"
                                  .expanded=${this._page === "tracking"}
                                  @time-entry-updated=${() => this._refresh()}
                              ></ptc-staff-tracking>
                          </div>
                      `
                    : ""}
                ${app.hasPermission("staff.roster")
                    ? html`
                          <div
                              class="roster section double-margined card vertical layout animate ${this._page ===
                              "roster"
                                  ? "expanded"
                                  : ""}"
                              @click=${() =>
                                  this._page !== "roster" && this.go("roster", { from: toDateString(new Date()) })}
                          >
                              <div class="horizontal center-aligning layout section-header">
                                  <button class="transparent small close-button" @click=${this._closeSection}>
                                      <i class="chevron-left"></i>
                                  </button>
                                  <div class="larger small-caps stretch collapse ellipsis left-margined">
                                      Dienstplan
                                  </div>
                                  ${rosterChanges
                                      ? html`
                                            <div class="small orange strong shining pill">
                                                <strong>${rosterChanges}</strong> ${rosterChanges === 1
                                                    ? "Änderung"
                                                    : "Änderungen"}
                                            </div>
                                        `
                                      : ""}
                                  ${available
                                      ? html`
                                            <div class="small purple strong shining left-margined pill">
                                                <strong>${available}</strong> verfügbar
                                            </div>
                                        `
                                      : ""}
                                  <button class="transparent small open-button">
                                      <i class="chevron-right"></i>
                                  </button>
                              </div>
                              <ptc-staff-roster
                                  class="stretch"
                                  .expanded=${this._page === "roster"}
                                  .date=${this.date}
                                  @roster-loaded=${() => this.requestUpdate()}
                              ></ptc-staff-roster>
                          </div>
                      `
                    : ""}
                ${app.hasPermission("staff.time")
                    ? html`
                          <div
                              class="roster section double-margined card vertical layout animate ${this._page === "time"
                                  ? "expanded"
                                  : ""}"
                              @click=${() =>
                                  this._page !== "time" && this.go("time", { from: toDateString(new Date()) })}
                          >
                              <div class="horizontal center-aligning layout section-header">
                                  <button class="transparent small close-button" @click=${this._closeSection}>
                                      <i class="chevron-left"></i>
                                  </button>
                                  <div class="larger small-caps stretch collapse ellipsis left-margined">
                                      Arbeitszeiten
                                  </div>
                                  <button class="transparent small open-button">
                                      <i class="chevron-right"></i>
                                  </button>
                              </div>
                              <ptc-staff-time
                                  class="stretch"
                                  .expanded=${this._page === "time"}
                                  .date=${this.date}
                                  @roster-loaded=${() => this.requestUpdate()}
                              ></ptc-staff-time>
                          </div>
                      `
                    : ""}
                ${app.hasPermission("staff.absences")
                    ? html`
                          <div
                              class="roster section double-margined card vertical layout animate ${this._page ===
                              "absences"
                                  ? "expanded"
                                  : ""}"
                              @click=${() => this._page !== "absences" && this.go("absences")}
                          >
                              <div class="horizontal center-aligning layout section-header">
                                  <button class="transparent small close-button" @click=${this._closeSection}>
                                      <i class="chevron-left"></i>
                                  </button>
                                  <div class="larger small-caps stretch left-margined">Urlaub &amp; Krankmeldungen</div>
                                  <button class="transparent small open-button">
                                      <i class="chevron-right"></i>
                                  </button>
                              </div>
                              <ptc-staff-absences
                                  class="stretch"
                                  .expanded=${this._page === "absences"}
                                  .date=${this.date}
                              ></ptc-staff-absences>
                          </div>
                      `
                    : ""}
                ${app.hasPermission("staff.availabilities")
                    ? html`
                          <div
                              class="roster section double-margined card vertical layout animate ${this._page ===
                              "availabilities"
                                  ? "expanded"
                                  : ""}"
                              @click=${() => this._page !== "availabilities" && this.go("availabilities")}
                          >
                              <div class="horizontal center-aligning layout section-header">
                                  <button class="transparent small close-button" @click=${this._closeSection}>
                                      <i class="chevron-left"></i>
                                  </button>
                                  <div class="larger small-caps stretch left-margined">Verfügbarkeiten</div>
                                  <button class="transparent small open-button">
                                      <i class="chevron-right"></i>
                                  </button>
                              </div>
                              <ptc-staff-availabilities
                                  class="stretch"
                                  .expanded=${this._page === "availabilities"}
                                  .date=${this.date}
                              ></ptc-staff-availabilities>
                          </div>
                      `
                    : ""}
                ${app.hasPermission("staff.documents")
                    ? html`
                          <div
                              class="roster section double-margined card vertical layout animate ${this._page ===
                              "documents"
                                  ? "expanded"
                                  : ""}"
                              @click=${() => this._page !== "documents" && this.go("documents")}
                          >
                              <div class="horizontal center-aligning layout section-header">
                                  <button class="transparent small close-button" @click=${this._closeSection}>
                                      <i class="chevron-left"></i>
                                  </button>
                                  <div class="larger small-caps stretch left-margined">Dokumente</div>
                                  <button class="transparent small open-button">
                                      <i class="chevron-right"></i>
                                  </button>
                              </div>
                              <ptc-staff-documents
                                  class="stretch"
                                  .expanded=${this._page === "documents"}
                              ></ptc-staff-documents>
                          </div>
                      `
                    : ""}

                <div class="larger double-margined horizontal spacing evenly stretching layout animate">
                    <button class="ghost" @click=${() => this._helpWidget.show({ displaySupportButton: false })}>
                        <i class="life-ring"></i> Hilfe
                    </button>
                    <button class="ghost" @click=${() => this.go("settings")}><i class="cog"></i> Einstellungen</button>
                </div>

                <div style="height: 3em"></div>

                <div class="double-margined centering vertical layout animate">
                    <a href="https://pentacode.app">
                        <img .src=${logo} class="logo" />
                    </a>
                    <div class="faded semibold">Mitarbeiter-App v${version}</div>
                </div>
            </div>
        `;
    }
}
