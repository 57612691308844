import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import {
    Absence,
    TimeEntryType,
    timeEntryTypeColor,
    VacationBalance,
    getRange,
    dateAdd,
    parseDateString,
    toDateString,
    formatRange,
    GetBalancesParams,
    RequestAbsenceParams,
    DateRange,
    getVacationEntitlement,
} from "@pentacode/core";
import { app } from "../../init";
import { Dialog } from "../dialog";
import { alert } from "../alert-dialog";
import { DateInput } from "../date-input";
import { DateString } from "@pentacode/openapi";

@customElement("ptc-staff-request-absence-dialog")
export class RequestAbsenceDialog extends Dialog<TimeEntryType, Absence> {
    @query("form")
    private _form: HTMLFormElement;

    @query("[name='start']")
    private _startInput: DateInput;

    @query("[name='end']")
    private _endInput: DateInput;

    @state()
    private _type: TimeEntryType.Vacation | TimeEntryType.Sick;

    @state()
    private _vacationBalances: VacationBalance[] = [];

    @state()
    private _commonWorkDays: number[] = [];

    private get _data() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);
        return {
            start: data.get("start") as DateString,
            end: data.get("end") ? dateAdd(data.get("end") as DateString, { days: 1 }) : undefined,
            message: data.get("message") as string,
        };
    }

    private get _days() {
        if (!this._data) {
            return [];
        }
        const { start, end } = this._data;

        if (!start || !end) {
            return [];
        }

        const days: string[] = [];
        let date = start;

        while (date < end) {
            days.push(date);
            date = dateAdd(date, { days: 1 });
        }

        return days;
    }

    async show(type: TimeEntryType.Vacation | TimeEntryType.Sick) {
        this._type = type;
        const promise = super.show();
        this._lastRangeLoaded = undefined;
        await this._load();
        await this.updateComplete;
        this._startInput.value = null;
        this._endInput.value = null;
        setTimeout(() => this._startInput.focus(), 500);
        return promise;
    }

    private _lastRangeLoaded?: DateRange;

    private async _load() {
        const data = this._data;

        if (!data || !data.start || !data.end || data.end < data.start || !app.profile) {
            this.requestUpdate();
            return;
        }

        const from = getRange(data.start, "year").from;
        const to = getRange(data.end, "year").to;

        if (this._lastRangeLoaded && from >= this._lastRangeLoaded.from && to <= this._lastRangeLoaded.to) {
            this.requestUpdate();
            return;
        }

        this.loading = true;
        try {
            const [vacationBalances, commonWorkDays] = await Promise.all([
                app.api.getVacationBalances(
                    new GetBalancesParams({
                        filters: [{ type: "employeeId", value: app.profile.id }],
                        from,
                        to,
                        resolution: "year",
                    })
                ),
                app.loadCommonWorkDays(app.profile.id, data.start, dateAdd(data.start, { years: -1 })), // Don't go further than a year into the past
            ]);
            this._vacationBalances = vacationBalances;
            this._commonWorkDays = commonWorkDays;
            this._lastRangeLoaded = { from, to };
        } catch (e) {
            console.error(e);
        }

        this.loading = false;

        this.requestUpdate();
    }

    private _isCommonDay(date: string) {
        if (!app.profile) {
            return false;
        }
        const d = parseDateString(date)!;
        const contract = app.profile.getContractForDate(date);
        if (contract && contract.fixedWorkDays) {
            return !!contract.hoursPerDay && contract.hoursPerDay[(d.getDay() + 6) % 7] !== 0;
        }
        return this._commonWorkDays.includes(d.getDay());
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();
        const { start, end, message } = this._data!;

        this.loading = true;
        try {
            const absence = await app.api.requestAbsence(
                new RequestAbsenceParams({ start, end: end!, message, type: this._type })
            );
            this.done(absence);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this.loading = false;
    }

    private _startChanged() {
        if (this._startInput.reportValidity()) {
            this._endInput.min = this._startInput.value!;
        }
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        css`
            .inner {
                max-width: 30em;
            }
        `,
    ];

    renderContent() {
        const data = this._data;
        const type = this._type;

        const maxMonth =
            this._type === TimeEntryType.Vacation
                ? dateAdd(toDateString(new Date()), {
                      months: 18,
                  })
                : undefined;

        return html`
            <form @submit=${this._submit} class="padded" @input=${() => this.requestUpdate()}>
                <div class="padded margined relative">
                    <div class="big colored-text text-centering" style="--color-highlight: ${timeEntryTypeColor(type)}">
                        <i class="${app.localized.timeEntryTypeIcon(type)}"></i>
                        ${type === TimeEntryType.Vacation ? "Urlaub Beantragen" : "Krankmeldung"}
                    </div>
                </div>

                <div class="margined">
                    <div class="horizontal evenly stretching spacing layout">
                        <div class="vertical layout">
                            <label>Von</label>
                            <ptc-date-input
                                name="start"
                                required
                                @input=${this._load}
                                .min=${dateAdd(toDateString(new Date()), {
                                    days: this._type === TimeEntryType.Vacation ? 1 : 0,
                                })}
                                .max=${maxMonth}
                                @change=${this._startChanged}
                                datePicker="popover"
                            ></ptc-date-input>
                        </div>
                        <div class="vertical layout">
                            <label>Bis</label>
                            <ptc-date-input
                                name="end"
                                required
                                @input=${this._load}
                                .min=${data && data.start}
                                .max=${maxMonth}
                                @change=${(e: Event) => (e.target as DateInput).reportValidity()}
                                datePicker="popover"
                            ></ptc-date-input>
                        </div>
                    </div>

                    <div class="double-margined stats centering horizontal layout text-centering">
                        ${this._type === TimeEntryType.Vacation
                            ? this._vacationBalances.map((balance) => {
                                  const vacationEntitlement = getVacationEntitlement(balance);
                                  const daysRequired = this._days.reduce(
                                      (total, date) =>
                                          date >= balance.from && date < balance.to && this._isCommonDay(date)
                                              ? total + 1
                                              : total,
                                      0
                                  );
                                  if (!daysRequired) {
                                      return;
                                  }
                                  const daysLeft = Math.floor(vacationEntitlement.remaining - daysRequired);
                                  return html`
                                      <div class="horizontally-margined">
                                          <div class="semibold blue colored-text stats-label">
                                              Resturlaub ${formatRange(balance)}
                                          </div>
                                          <div class="huge">
                                              ${Math.floor(vacationEntitlement.remaining)}
                                              <i class="smaller arrow-right"></i>
                                              <span class="colored-text ${daysLeft < 0 ? "red" : "green"}"
                                                  >${daysLeft}
                                              </span>
                                          </div>
                                      </div>
                                  `;
                              })
                            : ""}
                    </div>

                    <div>
                        <label>Bemerkungen</label>
                        <textarea name="message" class="fill-horizontally"></textarea>
                    </div>
                </div>
                <div class="padded spacing evenly stretching horizontal layout">
                    <button class="primary">Abschicken</button>
                    <button class="transparent" type="button" @click=${() => this.dismiss()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
